//@ts-check
import React from 'react'
import useStudentClassroom from 'hooks/useStudentClassroom'
import { H2 } from 'components/typography'
import ClassroomReconnecting from '../ClassroomReconnecting'
import ClassroomTabs, { classroomTabIds } from './Tabs'
import ClassroomBlackboard from './Blackboard'
import ClassroomNotebook from './Notebook'
import ClassroomPhoto from './Photo'
import ClassroomVideo from './Video'
import ClassroomBot from './ClassroomBot'
import styles from './StudentClassroom.module.css'

function StudentClassroom({
  socket,
  student,
  isActive = false,
  teacherIsReconnecting = false,
  botData
}) {
  const {
    selectedTabByStudent,
    selectedTab,
    selectedTool,
    zoom,
    canvasBySection,
    learningVideo,
    selectedNotebookCategory,
    handleZoomChange,
    handleTabChange,
    handleToolChange,
    handleSaveCanvasContext,
    handleClearCanvasContext,
    handleLearningVideoChange,
    handleHangUpCall,
    handleNotebookCategory
  } = useStudentClassroom({
    socket,
    isActive,
    student: student._id,
    teacherIsReconnecting
  })
  return (
    <div className={isActive ? styles.container : ''} hidden={!isActive}>
      <ClassroomTabs
        // @ts-ignore
        studentOption={selectedTabByStudent}
        // @ts-ignore
        selectedOption={selectedTab}
        disabled={teacherIsReconnecting}
        barbeibotAccess={student.barbeibotAccess}
        onClick={handleTabChange}
      />
      {teacherIsReconnecting ? (
        <ClassroomReconnecting
          classNames={{ container: styles.reconnecting }}
        />
      ) : (
        selectedTab === classroomTabIds.none && (
          <H2 className={styles.emptyContent}>
            Vuelve a seleccionar una pestaña
          </H2>
        )
      )}
      <ClassroomBlackboard
        socket={socket}
        selectedTool={selectedTool}
        zoom={zoom}
        isActive={isActive}
        isVisible={
          selectedTab === classroomTabIds.blackboard && !teacherIsReconnecting
        }
        student={student._id}
        canvasContext={canvasBySection[classroomTabIds.blackboard]}
        onSaveCanvasContext={handleSaveCanvasContext}
        onClearCanvasContext={handleClearCanvasContext}
        onZoomChange={handleZoomChange}
        onToolChange={handleToolChange}
        onHangUp={handleHangUpCall}
      />
      <ClassroomNotebook
        socket={socket}
        selectedTool={selectedTool}
        zoom={zoom}
        isActive={isActive}
        isVisible={
          selectedTab === classroomTabIds.notebook && !teacherIsReconnecting
        }
        student={student._id}
        canvasContext={canvasBySection[classroomTabIds.notebook]}
        selectedNotebookCategory={selectedNotebookCategory}
        onSaveCanvasContext={handleSaveCanvasContext}
        onClearCanvasContext={handleClearCanvasContext}
        onZoomChange={handleZoomChange}
        onToolChange={handleToolChange}
        onHangUp={handleHangUpCall}
        onChangeSelectedCategory={handleNotebookCategory}
      />
      <ClassroomPhoto
        socket={socket}
        selectedTool={selectedTool}
        zoom={zoom}
        isActive={isActive}
        isVisible={
          selectedTab === classroomTabIds.photo && !teacherIsReconnecting
        }
        student={student._id}
        canvasContext={canvasBySection[classroomTabIds.photo]}
        onSaveCanvasContext={handleSaveCanvasContext}
        onClearCanvasContext={handleClearCanvasContext}
        onZoomChange={handleZoomChange}
        onToolChange={handleToolChange}
        onHangUp={handleHangUpCall}
      />
      <ClassroomBot
        imageLocation={botData.imageLocation || null}
        answer={botData.answer || ''}
        question={botData.question}
        isVisible={
          selectedTab === classroomTabIds.barbeibot && !teacherIsReconnecting
        }
        onHangUp={handleHangUpCall}
      />
      <ClassroomVideo
        learningVideo={learningVideo}
        hidden={
          !(selectedTab === classroomTabIds.video && isActive) ||
          teacherIsReconnecting
        }
        onSelect={handleLearningVideoChange}
        onHangUp={handleHangUpCall}
      />
    </div>
  )
}

export default StudentClassroom
