import React, { useEffect, useState } from 'react'
import Activity from 'assets/icons/Activity'
import Modal from 'components/modals/Modal'
import { H2, H3, Paragraph } from 'components/typography'
import styles from './NetworkStatus.module.css'

const INTERVAL = 60 * 1000

function NetworkStatus() {
  const [show, setShow] = useState(false)
  const [networkStatus, setNetworkStatus] = useState({
    alive: false,
    latency: 0,
    packetLoss: 0,
    type: '-',
    quality: ''
  })
  const getNetworkStatus = () =>
    window.electronAPI?.getNetworkStatus?.(setNetworkStatus)
  useEffect(() => {
    getNetworkStatus()
    const interval = setInterval(getNetworkStatus, INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [])
  if (!window.electronAPI) return null
  const goodQuality =
    !!networkStatus.alive &&
    ['Excelente', 'Buena'].includes(networkStatus.quality)
  return (
    <>
      <Activity
        color={goodQuality ? 'var(--seadapted-50)' : 'var(--sandground-50)'}
        className={styles.icon}
        onClick={() => {
          getNetworkStatus()
          setShow(true)
        }}
      />
      {show && (
        <Modal
          onCancel={() => setShow(false)}
          showActions={false}
          showCloseIcon
        >
          <div className={styles.networkContainer}>
            <H2 className={styles.header}>Estado de la Red</H2>

            <div className={styles.statusSection}>
              <H3 className={styles.subHeader}>Conectividad</H3>
              <Paragraph
                className={[
                  styles.status,
                  goodQuality ? styles.connected : styles.disconnected
                ].join(' ')}
              >
                {networkStatus.alive ? 'Conectado' : 'Desconectado'}
              </Paragraph>
            </div>

            <div className={styles.statusSection}>
              <H3 className={styles.subHeader}>Detalles de la Red</H3>
              <Paragraph>
                Latencia:{' '}
                <span className={styles.highlight}>
                  {networkStatus.latency ? `${networkStatus.latency} ms` : '-'}
                </span>
              </Paragraph>
              <Paragraph>
                Pérdida de Paquetes:{' '}
                <span className={styles.highlight}>
                  {networkStatus.packetLoss
                    ? `${networkStatus.packetLoss}%`
                    : '-'}
                </span>
              </Paragraph>
              <Paragraph>
                Tipo de Red:{' '}
                <span className={styles.highlight}>{networkStatus.type}</span>
              </Paragraph>
              <Paragraph>
                Calidad:{' '}
                <span className={styles.highlight}>
                  {networkStatus.quality}
                </span>
              </Paragraph>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default NetworkStatus
