//@ts-check
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { isEmpty } from 'ramda'
import QRCode from 'qrcode.react'
import config from 'config'
import { formatDay } from 'utils/date'
import { clear, drawLine, handleDownload } from 'utils/canvas'
import {
  requestImageList,
  sendImageBoardClear,
  sendImageLine,
  sendImageRotation,
  sendImageSelect
} from 'api/sockets'
import CoolAndSmart from 'assets/svg/CoolAndSmart'
import Reload from 'assets/icons/Reload'
import ImageIcon from 'assets/icons/Image'
import Blackboard from 'assets/icons/Blackboard'
import ZoomIn from 'assets/icons/ZoomIn'
import DownloadButton from 'components/buttons/Download'
import Button from 'components/buttons/Button'
import { H2, H5, Paragraph } from 'components/typography'
import Modal from 'components/modals/Modal'
import HangingUp from 'components/buttons/HangingUp'
import { classroomTabIds } from './Tabs'
import ClassroomToolbar, { brushColors, classroomToolbarIds } from '../Toolbar'
import StraightLinePreview from './common/StraightLinePreview'
import useStraightLine from './common/hooks/useStraightLine'
import styles from './Photo.module.css'

/** @type {{color: string, lineWidth: number} | any} */
const stateByTool = {
  [classroomToolbarIds.draw]: { color: brushColors[0], lineWidth: 2 },
  [classroomToolbarIds.line]: { color: brushColors[0], lineWidth: 2 },
  [classroomToolbarIds.erase]: { color: 'transparent', lineWidth: 30 }
}

function ClassroomPhoto({
  socket,
  selectedTool,
  onToolChange,
  zoom,
  isActive,
  isVisible,
  student,
  canvasContext,
  onSaveCanvasContext,
  onClearCanvasContext,
  onZoomChange,
  onHangUp
}) {
  const [tool, setTool] = useState(stateByTool[classroomToolbarIds.draw])
  const [step, setStep] = useState(1)
  const [isDrawing, setDrawing] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [images, setImages] = useState({})
  const [rotation, setRotation] = useState({})
  const [showZoom, setShowZoom] = useState(false)

  const canvasRef = useRef(null)

  const handleSaveContext = useCallback(
    () =>
      canvasRef.current &&
      canvasRef.current.width &&
      canvasRef.current.height &&
      onSaveCanvasContext(
        [
          canvasRef.current
            .getContext('2d', { willReadFrequently: true })
            .getImageData(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            )
        ],
        classroomTabIds.photo
      ),

    [onSaveCanvasContext]
  )

  const handleRestoreCanvasContext = useCallback(() => {
    canvasContext?.map(imageData =>
      canvasRef.current
        .getContext('2d', { willReadFrequently: true })
        .putImageData(imageData, 0, 0)
    )
  }, [canvasContext])

  const handleClearCanvasContext = useCallback(() => {
    onClearCanvasContext(classroomTabIds.photo)
    clear(canvasRef)
  }, [onClearCanvasContext])

  const {
    isDrawingStraightLine,
    firstLinePoint,
    lineStylePreview,
    handleDrawStraightLine,
    handleShowStraightLinePreview,
    resetStraightLineState
  } = useStraightLine({
    selectedTool,
    canvasRef,
    tool,
    socket,
    student,
    handleSaveContext,
    sendWhiteboardLine: sendImageLine
  })

  const scaleToFit = img => {
    const blackboard = canvasRef.current
    const ctx = blackboard.getContext('2d', { willReadFrequently: true })
    const scale = Math.min(
      blackboard.width / img.width,
      blackboard.height / img.height
    )
    const x = blackboard.width / 2 - (img.width / 2) * scale
    const y = blackboard.height / 2 - (img.height / 2) * scale
    ctx.clearRect(0, 0, blackboard.width, blackboard.height)
    ctx.drawImage(img, x, y, img.width * scale, img.height * scale)
  }

  const drawRotated = useCallback((img, degrees) => {
    const blackboard = canvasRef.current
    if (!blackboard) return
    const rotatedDimensions = degrees % 180 !== 0
    blackboard.width = blackboard.getBoundingClientRect().width
    blackboard.height = blackboard.getBoundingClientRect().height
    const ctx = blackboard.getContext('2d', { willReadFrequently: true })
    ctx.clearRect(0, 0, blackboard.width, blackboard.height)
    ctx.save()
    ctx.translate(blackboard.width / 2, blackboard.height / 2)
    ctx.rotate((degrees * Math.PI) / 180)
    const wrh = rotatedDimensions
      ? img.height / img.width
      : img.width / img.height
    let newWidth = blackboard.width
    let newHeight = newWidth / wrh
    if (newHeight > blackboard.height) {
      newHeight = blackboard.height
      newWidth = newHeight * wrh
    }
    if (rotatedDimensions)
      ctx.drawImage(
        img,
        -blackboard.height / 2,
        -blackboard.width / 2,
        newHeight,
        newWidth
      )
    else
      ctx.drawImage(
        img,
        -blackboard.width / 2,
        -blackboard.height / 2,
        newWidth,
        newHeight
      )
    ctx.restore()
  }, [])

  const handleRotation = useCallback(() => {
    const image = images[student]
    const imageRotation = rotation[student] || 0
    if (!image || !isCanvasReady) return
    const degrees = imageRotation === 360 ? 90 : imageRotation + 90
    const img = new Image()
    img.onload = () => {
      drawRotated(img, degrees)
      setRotation(currentRotation => ({
        ...currentRotation,
        [student]: degrees
      }))
    }
    img.crossOrigin = 'anonymous'
    img.src = `${image.url}`
    sendImageRotation({ socket, student, rotationDegree: degrees })
  }, [drawRotated, images, isCanvasReady, rotation, socket, student])

  const handleClearBoard = useCallback(() => {
    if (!student) return
    sendImageBoardClear({ socket, student })
    handleClearCanvasContext()
    const image = images[student]
    if (!image) return
    const imageDOM = new Image()
    imageDOM.crossOrigin = 'anonymous'
    imageDOM.src = image.url
    imageDOM.onload = () => scaleToFit(imageDOM)
  }, [handleClearCanvasContext, images, socket, student])

  const handleClickToolbar = id => {
    if (id === classroomToolbarIds.eraseAll) return handleClearBoard()
    if (isDrawingStraightLine) resetStraightLineState()
    onToolChange(id)
  }

  const handleSetBrushColor = color => setTool(state => ({ ...state, color }))
  const handleKeyDown = e => {
    e.preventDefault()
    if (e.key === 'Escape') resetStraightLineState()
  }
  const throttle = (callback, delay) => {
    let previousCall = new Date().getTime()
    return function () {
      const time = new Date().getTime()
      if (time - previousCall >= delay) {
        previousCall = time
        callback.apply(null, arguments)
      }
    }
  }

  const handleMouseDown = e => {
    const image = images[student]
    if (!student || !image || isDrawingStraightLine) return
    const { clientY, clientX } = e
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }
  const handleMouseMove = e => {
    const image = images[student]
    if (!student || !image) return
    if (isDrawingStraightLine) return handleShowStraightLinePreview(e)
    if (!isDrawing || !student || !image) return
    const { clientY, clientX } = e
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendImageLine({
      socket,
      student,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    })
    setPosition({ x: endX, y: endY })
  }
  const handleMouseLeave = e => {
    const image = images[student]
    if (!isDrawing || !student || !image) return
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = e.clientX - x
    const endY = e.clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendImageLine({
      socket,
      student,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth,
      saveContext: true
    })
    setDrawing(false)
  }
  const handleTouchDown = e => {
    const image = images[student]
    if (!isActive || !image) return
    if (isDrawingStraightLine)
      return handleDrawStraightLine({
        ...e,
        clientX: e.touches[0]?.clientX,
        clientY: e.touches[0]?.clientY
      })
    const { clientY, clientX } = e.touches[0]
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }
  const handleTouchMove = e => {
    const image = images[student]
    if (!image) return
    if (isDrawingStraightLine)
      return handleShowStraightLinePreview({
        ...e,
        clientX: e.touches[0]?.clientX,
        clientY: e.touches[0]?.clientY
      })
    if (!isDrawing) return
    const { clientY, clientX } = e.touches[0]
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendImageLine({
      socket,
      student,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    })
    setPosition({ x: endX, y: endY })
  }
  const handleTouchLeave = () => {
    const image = images[student]
    if (!isDrawing || !image) return
    setDrawing(false)
  }

  const handleImageSelect = (image = {}) => {
    if (!student || !image.id) return
    setImages(currentImages => ({
      ...currentImages,
      [student]: {
        url: image.location,
        id: image.id,
        thumbnail: image.thumbnail
      }
    }))
    setRotation(currentRotation => ({
      ...currentRotation,
      [student]: 0
    }))
    setStep(0)
  }

  useEffect(() => {
    if (!socket || !canvasRef || !canvasRef.current) return

    socket.on(
      'classroom:students:image-line',
      ({ studentId, x0, y0, x1, y1, color, lineWidth, saveContext }) => {
        if (studentId !== student) return
        const { width, height } = canvasRef.current
        const startX = x0 * width
        const startY = y0 * height
        const endX = x1 * width
        const endY = y1 * height
        drawLine(canvasRef, startX, startY, endX, endY, color, lineWidth)
        saveContext && handleSaveContext()
      }
    )
    return () => {
      if (!socket || !isActive) return
      socket.off('classroom:students:image-line')
    }
  }, [handleSaveContext, socket, student, isActive])

  useLayoutEffect(() => {
    if (!isActive || isCanvasReady || step != 0) return
    canvasRef.current.width = canvasRef.current.getBoundingClientRect().width
    canvasRef.current.height = canvasRef.current.getBoundingClientRect().height
    setIsCanvasReady(true)
  }, [isActive, isCanvasReady, step])

  const targetImage = images[student] && images[student].url

  useEffect(() => {
    if (!isCanvasReady || step != 0) return
    if (!targetImage) return
    const imageDOM = new Image()
    imageDOM.crossOrigin = 'anonymous'
    imageDOM.src = targetImage
    imageDOM.onload = () => scaleToFit(imageDOM)
    sendImageSelect({ socket, student, image: targetImage })
  }, [images, isCanvasReady, socket, step, student, targetImage])

  useLayoutEffect(() => {
    if (isDrawing) return
    handleSaveContext()
  }, [handleSaveContext, isDrawing])

  return (
    <div
      className={styles.blackboard}
      hidden={!isVisible}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className={styles.innerContainer}>
        {step === 1 && (
          <div className={styles.step1}>
            <CoolAndSmart className={styles.svg} />
            <H2>No todo está en los libros</H2>
            <Button
              label='Subir desde el móvil'
              size='small'
              onClick={() => setStep(2)}
            />
            <a href={`/upload-photo/${student}`} target='_blank'>
              <Button label='Subir desde el ordenador' size='small' />
            </a>
            <Button
              label='Cargar desde la librería'
              size='small'
              onClick={() => setStep(3)}
            />
          </div>
        )}
        {step === 2 && (
          <div className={styles.step2}>
            <CoolAndSmart className={styles.svg} />
            <QRCode
              value={`${config.host}/upload-photo/${student}`}
              size={180}
            />
            <Paragraph type='body1Bold'>
              Escanea el código QR y sube tu foto
            </Paragraph>
            <Button
              type='secondary'
              size='small'
              label='Volver'
              onClick={() => setStep(1)}
            />
          </div>
        )}
        {step === 3 && (
          <ImageSync
            socket={socket}
            student={student}
            onImageSelect={handleImageSelect}
            onBack={() => setStep(1)}
          />
        )}
        <div className={styles.canvasArea} hidden={step != 0}>
          <canvas
            ref={canvasRef}
            className={styles.canvas}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseLeave}
            onMouseOut={handleMouseLeave}
            onMouseMove={throttle(handleMouseMove, 10)}
            onTouchStart={handleTouchDown}
            onTouchEnd={handleTouchLeave}
            onTouchCancel={handleTouchLeave}
            onTouchMove={throttle(handleTouchMove, 10)}
            onClick={handleDrawStraightLine}
          />
          <StraightLinePreview
            firstLinePoint={firstLinePoint}
            lineStylePreview={lineStylePreview}
          />
        </div>
      </div>
      <div className={styles.tools}>
        <ClassroomToolbar
          selectedOption={selectedTool}
          optionIdsToHide={[
            classroomToolbarIds.type,
            classroomToolbarIds.erase
          ]}
          brushColor={tool.color}
          onClick={handleClickToolbar}
          onSetColor={handleSetBrushColor}
        />
        <HangingUp onHangUp={onHangUp} />
        <div className={styles.right}>
          <div className={styles.innerRight}>
            <div
              className={styles.icons}
              title='Rotar'
              onClick={handleRotation}
            >
              <Reload />
            </div>
            <div
              className={[
                styles.icons,
                !targetImage ? styles.disabled : ''
              ].join(' ')}
              title='Zoom'
              onClick={() => targetImage && setShowZoom(true)}
            >
              <ZoomIn color='var(--dark-color)' />
            </div>
            {step === 0 ? (
              <div
                className={styles.icons}
                title='Elegir foto'
                onClick={() => setStep(1)}
              >
                <ImageIcon color='var(--dark-color)' />
              </div>
            ) : (
              <div
                className={styles.icons}
                title='Ver pizarra'
                onClick={() => setStep(0)}
              >
                <Blackboard color='var(--dark-color)' />
              </div>
            )}
          </div>
          <DownloadButton
            onClick={() => handleDownload(canvasRef)}
            disabled={canvasContext.length === 0}
          />
        </div>
      </div>
      {showZoom && (
        <ZoomImage image={targetImage} onClose={() => setShowZoom(false)} />
      )}
    </div>
  )
}

export default ClassroomPhoto

function ImageSync({ socket, student, onImageSelect, onBack }) {
  const [selectedImage, setSelectedImage] = useState(null)
  const [studentImages, setStudentImages] = useState([])

  useEffect(() => {
    setSelectedImage(null)
  }, [student, socket])

  useEffect(() => {
    if (!socket) return
    let isActive = true
    requestImageList({ socket, student })
      .then(images => {
        if (!isActive) return
        const sortedImages = images
          .map(image => ({
            ...image,
            timestamp: new Date(image.date).getTime(),
            day: formatDay(new Date(image.date))
          }))
          .sort((img1, img2) => img2.timestamp - img1.timestamp)
          .reduce((acc, curr) => {
            if (acc.length === 0) return [{ day: curr.day, elements: [curr] }]
            else if (acc[acc.length - 1].day === curr.day)
              return [
                ...acc.slice(0, -1),
                {
                  ...acc[acc.length - 1],
                  elements: [...acc[acc.length - 1].elements, curr]
                }
              ]
            else return [...acc, { day: curr.day, elements: [curr] }]
          }, [])
        setStudentImages(sortedImages)
      })
      .catch(console.error)
    return () => {
      isActive = false
    }
  }, [socket, student])

  const commonActions = (
    <div className={styles.imageSyncActions}>
      <Button type='secondary' size='small' label='Volver' onClick={onBack} />
      <Button
        label='Cargar'
        size='small'
        onClick={() => onImageSelect(selectedImage)}
        disabled={!selectedImage}
      />
    </div>
  )
  if (!studentImages || isEmpty(studentImages))
    return (
      <div className={styles.step3Empty}>
        <H2>
          Todavía no se han compartido <br /> imágenes
        </H2>
        {commonActions}
      </div>
    )
  return (
    <div className={styles.step3}>
      <div className={styles.imageSync}>
        {studentImages.map(day => (
          <div className={styles.day} key={day.day}>
            <H5 className={styles.title}>{day.day}</H5>
            <div className={styles.content}>
              {day.elements.map((image, index) => (
                <img
                  className={[
                    styles.image,
                    selectedImage && selectedImage.id === image.id
                      ? styles.selected
                      : ''
                  ].join(' ')}
                  onClick={() => setSelectedImage(image)}
                  onDoubleClick={() => {
                    setSelectedImage(image)
                    onImageSelect(image)
                  }}
                  key={index}
                  src={image.thumbnail}
                  alt='Foto de mundoestudiante'
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      {commonActions}
    </div>
  )
}

function ZoomImage({ image, onClose }) {
  const [zoomToScreen, setZoomToScreen] = useState(false)
  const [rotation, setRotation] = useState(0)
  const zoomCanvas = useRef(null)

  useEffect(() => {
    if (!image) return
    const img = new Image()
    img.onload = () => drawRotated(img, rotation, zoomToScreen)
    img.crossOrigin = 'anonymous'
    img.src = image
  }, [image, rotation, zoomToScreen])

  const drawRotated = (img, degrees, zoomToScreen) => {
    const pizarra = zoomCanvas.current
    if (!pizarra) return
    const rotatedDimensions = degrees % 180 !== 0
    if (zoomToScreen) {
      pizarra.width = pizarra.getBoundingClientRect().width
      pizarra.height = pizarra.getBoundingClientRect().height
    } else {
      pizarra.width = rotatedDimensions ? img.height : img.width
      pizarra.style.width = rotatedDimensions ? img.height : img.width
      pizarra.height = rotatedDimensions ? img.width : img.height
      pizarra.style.height = rotatedDimensions ? img.width : img.height
    }
    const ctx = pizarra.getContext('2d', {
      willReadFrequently: true
    })
    ctx.clearRect(0, 0, pizarra.width, pizarra.height)
    ctx.save()
    ctx.translate(pizarra.width / 2, pizarra.height / 2)
    ctx.rotate((degrees * Math.PI) / 180)
    const wrh = rotatedDimensions
      ? img.height / img.width
      : img.width / img.height
    let newWidth = pizarra.width
    let newHeight = newWidth / wrh
    if (newHeight > pizarra.height) {
      newHeight = pizarra.height
      newWidth = newHeight * wrh
    }
    if (rotatedDimensions)
      ctx.drawImage(
        img,
        -pizarra.height / 2,
        -pizarra.width / 2,
        newHeight,
        newWidth
      )
    else
      ctx.drawImage(
        img,
        -pizarra.width / 2,
        -pizarra.height / 2,
        newWidth,
        newHeight
      )
    ctx.restore()
  }

  const handleRotate = () =>
    setRotation(currentRotation => (currentRotation + 90) % 360)

  const handleClose = useCallback(() => {
    setZoomToScreen(false)
    setRotation(0)
    onClose()
  }, [onClose])
  return (
    <Modal
      okText='Girar'
      cancelText='Cerrar'
      onOk={handleRotate}
      onCancel={handleClose}
    >
      <canvas
        className={[
          styles.zoomImageAdjusted,
          zoomToScreen ? styles.canvasAdjusted : ''
        ].join(' ')}
        ref={zoomCanvas}
        onClick={() => setZoomToScreen(v => !v)}
      />
    </Modal>
  )
}
