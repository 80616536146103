import React from 'react'
import RingsSpinner from './RingsSpinner'
import styles from './LoadingPage.module.css'

function LoadingPage() {
  return (
    <div className={styles.main}>
      <RingsSpinner />
    </div>
  )
}

export default LoadingPage
