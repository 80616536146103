import React, { useEffect, useState } from 'react'
import GraphBar from 'assets/icons/GraphBar'
import Modal from 'components/modals/Modal'
import { H2, H3, Paragraph } from 'components/typography'
import StatusIndicator from '../StatusIndicator'
import styles from './SystemStats.module.css'

const MINIMUM_REQUIREMENTS = {
  totalMemory: 4 * 1024 * 1024 * 1024, // 4 GB de RAM
  cpuCores: 2, // Al menos 2 núcleos de CPU
  cpuSpeed: 2.4 // Depende del SO
}

function SystemStats() {
  const [show, setShow] = useState(false)
  const [stats, setStats] = useState({
    totalMem: 0,
    freeMem: 0,
    memoryUsage: { rss: 0, heapTotal: 0, heapUsed: 0, external: 0 },
    cpuCores: 1,
    cpuUsage: { user: 0, system: 0 },
    cpuModel: '',
    cpuSpeed: '',
    loadAvg: [0, 0, 0],
    uptime: null
  })
  const getSystemStats = () => window.electronAPI?.getSystemStats?.(setStats)

  useEffect(() => {
    getSystemStats()
  }, [])

  if (!window.electronAPI) return null

  const freeMemGB = (stats.freeMem / (1024 * 1024 * 1024)).toFixed(2)
  const totalMemGB = (stats.totalMem / (1024 * 1024 * 1024)).toFixed(2)
  const freeMemPercentage = ((freeMemGB / totalMemGB) * 100).toFixed(2)

  const valid = isValidSystem(stats)
  return (
    <>
      <GraphBar
        color={
          !!valid?.success ? 'var(--seadapted-50)' : 'var(--sandground-50)'
        }
        className={styles.icon}
        onClick={() => {
          getSystemStats()
          setShow(true)
        }}
      />
      {show && (
        <Modal
          onCancel={() => setShow(false)}
          showActions={false}
          showCloseIcon
        >
          <div className={styles.monitorContainer}>
            <H2 className={styles.header}>
              Monitorización <StatusIndicator success={valid.success} />
            </H2>
            <div className={styles.section}>
              <Paragraph
                type='body1Bold'
                className={
                  valid.success ? styles.validSystem : styles.invalidSystem
                }
              >
                {valid?.message}
              </Paragraph>
            </div>
            <div className={styles.section}>
              <H3 className={styles.subHeader}>Carga del Sistema</H3>
              <Paragraph>
                Últimos 15 minutos:{' '}
                <span className={styles.highlight}>
                  {loadMessage(stats.loadAvg[2], stats.cpuCores)}
                </span>
              </Paragraph>
            </div>

            <div className={styles.section}>
              <H3 className={styles.subHeader}>Información CPU</H3>
              <Paragraph>
                Número de núcleos:{' '}
                <span className={styles.highlight}>{stats.cpuCores}</span>
              </Paragraph>
              <Paragraph>
                Modelo:{' '}
                <span className={styles.highlight}>{stats.cpuModel}</span>
              </Paragraph>
              <Paragraph>
                Velocidad:{' '}
                <span className={styles.highlight}>
                  {(stats.cpuSpeed / 1000).toFixed(2)} GHz
                </span>
              </Paragraph>
            </div>

            <div className={styles.section}>
              <H3 className={styles.subHeader}>Uso de Memoria</H3>
              <Paragraph>
                Usada por la aplicación:{' '}
                <span className={styles.highlight}>
                  {(stats.memoryUsage.rss / (1024 * 1024)).toFixed(2)} MB
                </span>
              </Paragraph>
              <Paragraph>
                RAM Libre:{' '}
                <span className={styles.highlight}>
                  {freeMemGB} GB - {freeMemPercentage}%
                </span>
              </Paragraph>
              <Paragraph>
                RAM total:{' '}
                <span className={styles.highlight}>{totalMemGB} GB</span>
              </Paragraph>
            </div>

            <div className={styles.section}>
              <H3 className={styles.subHeader}>Tiempo de Actividad</H3>
              <Paragraph>
                Ordenador encendido:{' '}
                <span className={styles.highlight}>
                  {formatUptime(stats.uptime)}
                </span>
              </Paragraph>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default SystemStats

function formatUptime(seconds) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  return `${hours} horas, ${minutes} minutos y ${secs} segundos`
}

function loadMessage(load, cores) {
  if (load <= cores * 0.7) return 'Ligera'
  if (load <= cores) return 'Moderada'
  return 'Alta'
}

function isValidSystem({ totalMem, cpuCores, cpuSpeed }) {
  if (totalMem < MINIMUM_REQUIREMENTS.totalMemory)
    return {
      success: false,
      message: 'El sistema no tiene suficiente memoria RAM.'
    }

  if (cpuCores < MINIMUM_REQUIREMENTS.cpuCores)
    return {
      success: false,
      message: 'El sistema no tiene suficientes núcleos de CPU.'
    }
  if ((cpuSpeed / 1000).toFixed(2) < MINIMUM_REQUIREMENTS.cpuSpeed)
    return {
      success: false,
      message: 'El sistema no tiene un procesador adecuado'
    }

  return {
    success: true,
    message: 'El sistema cumple con los requisitos mínimos.'
  }
}
